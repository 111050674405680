import React, { useEffect, useRef } from "react";
import "../assets/style/AdvantagesSection.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faShieldAlt,
  faRocket,
  faThumbsUp,
  faMoneyBillWave
} from "@fortawesome/free-solid-svg-icons"; // Example icons

function AdvantagesSection() {
  return (
    <section className="competitive-advantages-section px-5">
     
        <div className="   right-col">
          <div className="advantage-item">
            <div className="icon-circle">
              <FontAwesomeIcon icon={faShieldAlt} size="2x" />
            </div>
            <h3> حلول صديقة للبيئة</h3>
            <p>
            نهتم بالبيئة، ونستخدم منتجات تنظيف صديقة للبيئة وطرق توفر المياه.
            </p>
          </div>

          <div className="advantage-item">
            <div className="icon-circle">
              <FontAwesomeIcon icon={faRocket} size="2x" />
            </div>
            <h3> الراحة</h3>
            <p>
            نحن نقدم خدماتنا لك، سواء كانت لتنظيف منزلك أو سيارتك، مما يوفر لك الوقت والجهد.
            </p>
          </div>

          <div className="advantage-item">
            <div className="icon-circle">
              <FontAwesomeIcon icon={faThumbsUp} size="2x" />
            </div>
            <h3> جودة يمكنك الاعتماد عليها</h3>
            <p>
            نستخدم أفضل المنتجات وأحدث تقنيات التنظيف لضمان أن منزلك وسيارتك نظيفين ومعتنى بهما بشكل جيد.
            </p>
          </div>
          <div className="advantage-item">
            <div className="icon-circle">
              <FontAwesomeIcon icon={faMoneyBillWave} size="2x" />
            </div>
            <h3>التكلفة المناسبة</h3>
            <p>
            يوفر تطبيق يلق أسعارًا ملائمة لجميع المستخدمين من جميع فئات المجتمع.
            </p>
          </div>
        </div>
        <div className="left-col mx-2">
          <div className="icon-circle-white">
            <FontAwesomeIcon icon={faCheckCircle} size="2x" />
          </div>
          <h2 className="section-title"> ميزتنا التنافسية</h2>
          <p className="section-subtitle">لماذا تختار يلق ؟</p>
          <p className="text-white"> يلق في طليعة صناعة الخدمات حسب الطلب، حيث نقدم تجربة فريدة وسلسة للمستخدمين الباحثين عن خدمات تنظيف المنازل والعناية بالسيارات. نوفر حلولاً تلبي نمط الحياة السريع للمستهلكين اليوم </p>
          <button className="try-app-button">جرب يلق الأن</button>
        </div>
      
    </section>
  );
}

export default AdvantagesSection;
