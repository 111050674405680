import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../assets/style/terms.css'; 
import logo from '../assets/images/logo.png'; 

function PrivacyPolicy() {
  useEffect(() => {
    const firstRow = document.querySelector('.first-row');

    const handleScroll = () => {
      if (window.scrollY > 0) {
        firstRow.classList.add('sticky');
      } else {
        firstRow.classList.remove('sticky');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <header className="terms-header">
        <div className="first-row p-4">
          <img src={logo} alt="Logo" className="logo" />
          <nav className="navigation w-100 text-center">
            <Link to="/">الصفحة الرئيسية</Link>
            <Link to="/privacy-policy" className="active"> سياسة الخصوصية</Link>
          </nav>
        </div>
        <div className="row second-row px-4">
          <h1> سياسة الخصوصية لتطبيق يلق </h1>
        </div>
      </header>
      <div className="terms-content px-5" >
        <h2>سياسة الخصوصية</h2>
        <p>
          تلتزم شركة يلق بحماية خصوصية زوارنا وأعضائنا وتوضح سياسة الخصوصية التالية المعلومات التي قد تجمعها شركة يلق وكيف يمكننا استخدام هذه المعلومات لخدمة الزوار والأعضاء بشكل أفضل أثناء استخدام موقعنا الإلكتروني وتطبيق الهاتف المحمول.
        </p>
        <p>
          يرجى قراءة سياسة الخصوصية هذه بعناية لفهم سياساتنا وممارساتنا المتعلقة بمعلوماتك وكيفية تعاملنا معها. من خلال الوصول إلى أو استخدام موقعنا الإلكتروني وتطبيق الهاتف المحمول (الخدمة مجتمعة)، فإنك توافق على شروط سياسة الخصوصية هذه. قد تتغير سياسة الخصوصية هذه من وقت لآخر. يرجى مراجعتها بانتظام لضمان فهمك لممارسات الخصوصية لدينا.
        </p>
        <p>
          إذا كانت لديك أي أسئلة حول سياسة الخصوصية هذه، فيرجى الاتصال بنا على <a href='mailto:info@yeleg.online'>info@yeleg.online</a>.
        </p>
        <h3>المعلومات التي نجمعها</h3>
        <p>
          عند التسجيل في الخدمة (إما كعميل أو شريك أو مزود خدمة)، يتم تطوير ملف تعريف مستخدم لتحسين تجربة المستخدم. تتضمن حقول البيانات المطلوبة البريد الإلكتروني، كلمة المرور، الاسم، رقم الجوال، تاريخ الميلاد، والجنس.
        </p>
        <p>
          بالإضافة إلى ذلك، يتم جمع معلومات التتبع أثناء التنقل عبر تطبيقنا أو استخدام الخدمة، بما في ذلك على سبيل المثال لا الحصر، الموقع الجغرافي. إذا طلبت خدمة عبر التطبيق، فسيسجل الهاتف المحمول الخاص بمزود الخدمة إحداثيات GPS الخاصة بك.
        </p>
        <p>
          نجمع أيضًا نوع الجهاز والمعرف الفريد عند استخدامك لتطبيق الهاتف المحمول، ونستخدم هذه المعلومات فقط لتزويدك بأحدث التطبيقات والميزات. يمكنك أيضًا اختيار تحميل الصور أثناء استخدام الموقع أو التطبيق، وإذا قمت بذلك، فقد تكون قابلة للعرض من قبل مزودي الخدمة لدينا للتحقق من السيارة. يمكنك إزالة الصور أو تحديثها في أي وقت عن طريق تسجيل الدخول إلى حسابك.
        </p>
        <p>
          إذا كنت تستخدم خدماتنا من خلال جهازك المحمول، سنقوم بتتبع معلومات الموقع الجغرافي الخاص بك لتكون قادرًا على عرض مقدمي الخدمات في منطقتك وتحديد الموقع الذي ترغب في تقديم الخدمة فيه. لن نشارك هذه المعلومات مع أطراف ثالثة لأي غرض من الأغراض وسنستخدم هذه المعلومات فقط لغرض تلبية طلبك.
        </p>
        <h3>كيف نستخدم معلوماتك؟</h3>
        <p>
          هدفنا الرئيسي في جمع المعلومات هو تقديم تجربة محسنة عند استخدام الخدمة. نستخدم هذه المعلومات لمراقبة ميزات الخدمة الأكثر استخدامًا، للسماح لك بمشاهدة سجل خدماتك، وتخزين معلومات الدفع مع شريك الدفع المعتمد، وعرض أي عروض ترويجية، وتحديد الميزات التي نحتاج إلى التركيز عليها، بما في ذلك أنماط الاستخدام والمواقع الجغرافية.
        </p>
        <h3>المعلومات الخاصة بك</h3>
        <p>
          نحن لا نبيع أو نؤجر أو نتاجر بمعلوماتك الشخصية أو معلومات الموقع الجغرافي. سنستخدم هذه المعلومات فقط كما هو موضح في سياسة الخصوصية هذه.
        </p>
        <p>
          قد نشارك المعلومات المجمعة غير المحددة الهوية مع أطراف ثالثة لأغراض تحليل الصناعة، والتوصيف الديموغرافي، وتقديم إعلانات مستهدفة. نستخدم أيضًا خدمات شركات وأفراد من أطراف ثالثة لتسهيل خدمتنا، وتقديم الخدمة نيابة عنا، وتوفير دعم العملاء، ومعالجة الدفع. يحق لهذه الجهات الخارجية الوصول إلى معلوماتك الشخصية فقط لأداء هذه المهام نيابة عنا وهي ملزمة بعدم الكشف عنها أو استخدامها لأي غرض آخر.
        </p>
        <h3>التغييرات في سياسة الخصوصية</h3>
        <p>
          قد نقوم بتحديث سياسة الخصوصية هذه لتعكس التغييرات في ممارسات المعلومات الخاصة بنا. إذا قمنا بإجراء أي تغييرات جوهرية، فسنخطرك عبر البريد الإلكتروني أو عبر إشعار على الموقع قبل أن يصبح التغيير ساريًا. نشجعك على مراجعة هذه الصفحة بشكل دوري للبقاء على اطلاع على أحدث معلومات حول ممارسات الخصوصية لدينا.
        </p>
        <h3>سياسة خصوصية الدفع</h3>
        <p>
          لن يتم تخزين أي بيانات خاصة بالبطاقة الائتمانية أو بطاقة الخصم المباشر أو معلومات التعريف الشخصية أو مشاركتها أو تأجيرها لأي طرف خارجي. إذا قمت بالدفع مقابل خدماتنا عبر الموقع الإلكتروني، سيتم إرسال البيانات المطلوبة منك إلى مقدم خدمة الدفع عبر اتصال آمن.
        </p>
      </div>
      <div className="terms-footer">
        Powered by&nbsp;
        <a href="https://mobileaders.com/">Mobileaders</a>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
