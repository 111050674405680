import React from 'react';
import '../assets/style/availableAreasSection.css';
import mockup2 from '../assets/images/mockup2.png'

function AvailableAreasSection() {
  return (
    <section className="available-areas-section">
      <div className="content-wrapper">
        <h2>المناطق المتاحة</h2>
        <p> نحن فخورون بتقديم خدمات يلق في جميع أنحاء المملكة العربية السعودية. بغض النظر عن مكان تواجدك، يمكنك الاستفادة من خدماتنا المتميزة. هدفنا هو ضمان وصول خدماتنا عالية الجودة إلى كل عميل. من المدن الكبرى إلى المناطق النائية، نحن هنا لتلبية احتياجاتك وتقديم تجربة استثنائية في كل مرة</p>
        <img src={mockup2} alt="عرض التطبيق" className='map-image' />
      </div>
    </section>
  );
}

export default AvailableAreasSection;
