import React from 'react';
import { Link } from 'react-router-dom'; 
import '../assets/style/AddStrengthSection.css';

function AddStrengthSection() {
  return (
    <section className="add-strength-section">
      <div className="content-wrapper">
        <h2> قم بتعزيز مشروعك بالقوة والتميز مع يلق</h2>
        <p className='text-white'> مع خدمات يلق، يمكنك تعزيز قوة وتميز مشروعك. حمل التطبيق الآن لتكتشف المزيد</p>
        <div className="buttons-container">
        <Link to="/about" className="know-more-button">اعرف المزيد</Link>
          <Link to="#" className="download-button">حمل التطبيق</Link>
          
        </div>
      </div>
    </section>
  );
}

export default AddStrengthSection;
