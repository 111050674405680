import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom'; // Import useNavigate
import { FaBars, FaTimes } from 'react-icons/fa'; 
import logo from '../assets/images/logo.png';
import '../assets/style/navbar.css'; 

function Navbar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const navigate = useNavigate(); // Initialize navigate

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleScrollToSection = (sectionId) => {
    navigate('/'); // Navigate to the home page
    setTimeout(() => {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100); // Delay to allow navigation to complete
  };

  return (
    <div className="navbar-container">
      <nav className="header-navbar">
        <div className="navbar-logo">
          <RouterLink to="/">
            <img src={logo} alt="App Logo" />
          </RouterLink>
        </div>

        <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
          {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
        </div>

        <ul className={`navbar-links ${isMobileMenuOpen ? 'active' : ''}`}>
          <li>
            <RouterLink to="/"> الصفحة الرئيسية</RouterLink>
          </li>
          <li>
            <RouterLink to="/about">عن يلق</RouterLink>
          </li>
          <li>
            <RouterLink to="/steps"> خطوات طلب فرنشيز</RouterLink>
          </li>
          <li>
            <button onClick={() => handleScrollToSection('available-areas-section')}>المناطق المتاحة</button>
          </li>
          <li>
            <button onClick={() => handleScrollToSection('custom-faq-section')}>الأسئلة الشائعة</button>
          </li>
          <li>
            <button onClick={() => handleScrollToSection('download-app-section')}>تحميل التطبيق</button>
          </li>
          <li>
            <button onClick={() => handleScrollToSection('contact-section')}>تواصل معنا</button>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Navbar;
