import React from 'react';
import '../assets/style/AboutFranchise.css'; 
import hrImage from '../assets/images/hr.png';
import ContactSection from '../components/ContactSection';
import WhyYalegSection from '../components/WhyYalegSection';
import Navbar from '../components/Navbar'; 
import { Link } from 'react-router-dom';

function AboutFranchise() {
  return (
    <div>
      <Navbar />
      <div className="about-header">
        <h1>نموذج العمل</h1>
        <img src={hrImage} alt="hr img" className="download-hr-image" />
      </div>

      <section>
      <WhyYalegSection buttonText="طلب فرنشايز الان" buttonLink="/contact" />
      <div className="about-franchise-section">
      <div className="about-franchise-content" dir='rtl'>
        <h2>نموذج امتياز يلق</h2>
        <p>
          يقدم نموذج امتياز يلق للامتيازات فرصة الاستفادة من تدريب شامل، ودعم مستمر، وإشراف منتظم، بالإضافة إلى جهود تسويقية وامتثال كامل لجميع المتطلبات القانونية المتعلقة بالعمليات. تقدم يلق هذه الخدمات مقابل رسم شهري من صاحب الامتياز، يمكن أن يزيد بناءً على معايير محددة موضحة في دليل العمليات. يُطلب من أصحاب الامتياز تشغيل العمليات وفقاً للمعايير والإرشادات التي وضعها المانح، باستخدام الأدلة التشغيلية المقدمة لضمان أعلى جودة في خدمات تنظيف السيارات. يهدف هذا النموذج إلى إنشاء تعاون متبادل المنفعة يسمح للطرفين بتحقيق أقصى استفادة من الفرصة وتحقيق النجاح المشترك.
        </p>

        <h2>فرصة امتياز يلق</h2>
        <p>
          تقدم فرصة امتياز يلق فرصة ممتازة لأصحاب الامتياز للاستفادة من تدريب مكثف، ودعم مستدام، وإشراف منتظم. بالإضافة إلى ذلك، يقدم النموذج جهود تسويقية مبتكرة ويشمل الامتثال لجميع الالتزامات القانونية المتعلقة.
        </p>
        <p>
          المفهوم الأساسي لفرصة يلق هو تقديم هذه الخدمات المتميزة مقابل رسم شهري يدفعه صاحب الامتياز. يتم تحديد هذا الرسم بناءً على معايير محددة موضحة بالكامل في دليل العمليات. لضمان أعلى مستويات الجودة، يجب على أصحاب الامتياز أداء العمليات وفقاً للمبادئ والإرشادات المقدمة من يلق، باستخدام الإرشادات التشغيلية لتقديم أفضل خدمات تنظيف السيارات.
        </p>
        <p>
          يهدف هذا النموذج إلى إقامة شراكة متبادلة تسمح للطرفين بالاستفادة القصوى من الفرصة المتاحة وتحقيق النجاح المشترك الذي يعزز نمو الأعمال وتوسعها.
        </p>

        <h2>ابدأ معنا</h2>
        <p>
          هل لديك عمل في تنظيف السيارات أو نوع آخر من الأعمال باستخدام معدات متخصصة، وترغب في تطويره وتحويله إلى امتياز ناجح تحت مظلة يلق؟ يمكن أن يكون امتياز يلق الخطوة المثالية التالية لك. نقدم لك الفرصة لتوسيع نطاق عملك وزيادة تأثيرك في السوق.
        </p>

        <h2>فوائد تحويل عملك إلى امتياز يلق</h2>
        <ul>
          <li>الاستفادة من تجربة علامة تجارية معروفة وموثوقة.</li>
          <li>تحسين جودة الخدمات والعمليات بمساعدة خبرائنا.</li>
          <li>دعم تسويقي أقوى وإشراف معزز.</li>
          <li>رسم شهري مرن يمكن أن يزيد بناءً على أداء موقعك.</li>
        </ul>
        <div className='pt-5'>
        <Link to='/contact' className="try-app-button">
        طلب فرنشايز الان
        </Link>
        </div>
        
        </div>
       
        </div>

        
      </section>

      <ContactSection />
    </div>
  );
}

export default AboutFranchise;
