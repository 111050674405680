import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../assets/style/terms.css'; 
import logo from '../assets/images/logo.png'; 

function TermsAndConditions() {
  useEffect(() => {
    const firstRow = document.querySelector('.first-row');

    const handleScroll = () => {
      if (window.scrollY > 0) {
        firstRow.classList.add('sticky');
      } else {
        firstRow.classList.remove('sticky');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <header className="terms-header">
        <div className="first-row p-4">
          <img src={logo} alt="Logo" className="logo" />
          <nav className="navigation w-100 text-center">
            <Link to="/">الصفحة الرئيسية</Link>
            <Link to="/terms-and-conditions" className="active">الشروط والأحكام</Link>
          </nav>
        </div>
        <div className="row second-row px-4">
          <h1>الشروط والأحكام لتطبيق يلق</h1>
        </div>
      </header>
      <div className="terms-content px-5 " >
  

  <section>
    <h3>سياسة الخصوصية</h3>
    <p>
      تلتزم شركة "يلق" بحماية خصوصية زوارنا وأعضائنا. تحدد سياسة الخصوصية التالية المعلومات التي قد تجمعها "يلق" وكيفية استخدامنا لهذه المعلومات لخدمة الزوار والأعضاء بشكل أفضل أثناء استخدام تطبيقنا.
    </p>
    <p>
      يرجى قراءة سياسة الخصوصية هذه بعناية لفهم سياساتنا وممارساتنا المتعلقة بمعلوماتك وكيفية تعاملنا معها. من خلال الوصول إلى أو استخدام موقعنا على الويب وتطبيق الهاتف المحمول (الذين يشار إليهما معًا بالخدمة)، فإنك توافق على شروط سياسة الخصوصية هذه. قد تتغير سياسة الخصوصية هذه من وقت لآخر. يرجى مراجعتها بانتظام حتى تفهم ممارسات الخصوصية لدينا. إذا كانت لديك أي أسئلة حول سياسة الخصوصية هذه، يرجى الاتصال بنا على <a href='mailto:info@yeleg.online'>info@yeleg.online</a>.
    </p>
  </section>

  <section>
    <h3>المعلومات التي نجمعها</h3>
    <p>
      عند التسجيل في الخدمة (سواء كعميل أو شريك أو مزود خدمة)، يتم إنشاء ملف تعريف مستخدم لزيادة تخصيص تجربة المستخدم. تشمل حقول البيانات المطلوبة الحالية:
    </p>
    <ul>
      <li>البريد الإلكتروني</li>
      <li>كلمة المرور</li>
      <li>الاسم</li>
      <li>رقم الجوال</li>
      <li>تاريخ الميلاد</li>
      <li>الجنس</li>
      <li>السيارة ونوعها ولونها وحجمها</li>
    </ul>
    <p>
      بالإضافة إلى ذلك، يتم جمع معلومات التتبع أثناء التنقل عبر تطبيقنا أو استخدام الخدمة، بما في ذلك على سبيل المثال لا الحصر، المناطق الجغرافية. إذا طلبت غسيلًا عبر الخدمة، فإن الهاتف المحمول الخاص بالسائق سيسجل إحداثيات GPS الخاصة بك.
    </p>
    <p>نجمع أيضًا نوع الجهاز والمعرف الفريد عند استخدامك تطبيق الهاتف المحمول الخاص بنا، ونستخدم هذه المعلومات لغرض وحيد هو تزويدك بأحدث التطبيقات والميزات. يمكنك أيضًا اختيار تحميل الصور أثناء استخدام الموقع أو التطبيق، وإذا اخترت القيام بذلك، فقد تكون قابلة للعرض بواسطة مزودي خدمة غسيل السيارات للتحقق من سيارتك. يمكنك إزالة الصور أو تحديثها في أي وقت عن طريق تسجيل الدخول إلى حسابك. إذا كنت تستخدم خدماتنا من خلال جهازك المحمول، فسنقوم بتتبع معلومات الموقع الجغرافي الخاص بك حتى تكون قادرًا على عرض مقدمي خدمات غسيل السيارات في منطقتك القريبة من موقعك، وتعيين موقع الغسيل الخاص بك، ومقدمي الخدمات لدينا للعثور على الموقع الذي ترغب في غسل السيارة فيه. لن نشارك هذه المعلومات مع أطراف أخرى لأي غرض من الأغراض، وسوف نستخدم هذه المعلومات فقط لغرض وحيد هو تلبية طلبك.</p>
  </section>

  <section>
    <h3>ملفات تعريف الارتباط</h3>
    <p>
      ملفات تعريف الارتباط هي كمية صغيرة من البيانات التي يتم إرسالها إلى متصفحك من خادم الويب وتخزينها على القرص الصلب لجهاز الكمبيوتر الخاص بك. يمكن تعطيل ملفات تعريف الارتباط أو التحكم فيها عن طريق تحديد التفضيل داخل متصفح الويب الخاص بك.
    </p>
    <p>
    يجب أن يدرك مستخدمو موقع الويب والتطبيق أن المعلومات والبيانات غير الشخصية قد يتم جمعها تلقائيًا بحكم التشغيل القياسي لخوادم الكمبيوتر الخاصة بالشركة أو من خلال استخدام ملفات تعريف الارتباط. ملفات تعريف الارتباط هي ملفات يمكن أن يستخدمها موقع الويب للتعرف على المستخدمين المتكررين، والسماح لموقع ويب بتتبع سلوك استخدام الويب. تعمل ملفات تعريف الارتباط عن طريق تعيين رقم للمستخدم الذي لا معنى له خارج موقع التعيين. يجب أن يدرك المستخدمون أنه لا يمكن للشركة التحكم في استخدام ملفات تعريف الارتباط (أو المعلومات الناتجة) من قبل أطراف ثالثة. إذا كنت لا تريد أن يتم جمع المعلومات من خلال استخدام ملفات تعريف الارتباط، فإن متصفحك يسمح لك برفض أو قبول استخدام ملفات تعريف الارتباط. ومع ذلك، قد تكون هناك بعض ميزات الخدمة التي تتطلب استخدام ملفات تعريف الارتباط من أجل تخصيص إيصال المعلومات إليك.
    </p>
    <p>لن يتم تخزين أو بيع أو مشاركة أو تأجير جميع بيانات بطاقات الائتمان/الخصم ومعلومات التعريف الشخصية لأي أطراف ثالثة.</p>
  </section>
  <section>
    <h3> سياسة الأحكام </h3>
    <p>قد يتم تغيير سياسات وأحكام موقع الويب والتطبيق أو تحديثها من حين لآخر للوفاء بالمتطلبات والمعايير. لذلك يتم تشجيع العملاء على زيارة هذه الأقسام بشكل متكرر حتى يتم تحديثهم بشأن التغييرات على الموقع. ستكون التعديلات سارية المفعول في يوم نشرها.</p>
  </section>
  <section>
    <h3>  الاعلانات </h3>
    <p> يتم اختيار بعض الإعلانات التي قد تراها على الموقع وتسليمها بواسطة جهات خارجية، مثل شبكات الإعلانات ووكالات الإعلان والمعلنين ومقدمي شرائح الجمهور. قد تجمع هذه الأطراف الخارجية معلومات عنك وعن أنشطتك عبر الإنترنت، سواء على الموقع أو على مواقع أخرى، من خلال ملفات تعريف الارتباط وإشارات الويب والتقنيات الأخرى في محاولة لفهم اهتماماتك وإيصال الإعلانات التي تناسب اهتماماتك. يرجى تذكر أنه ليس لدينا إمكانية الوصول إلى أو التحكم في المعلومات التي قد تجمعها هذه الجهات الخارجية. لا تغطي سياسة الخصوصية هذه ممارسات المعلومات الخاصة بهذه الأطراف الثالثة.</p>
  </section>

  <section>
    <h3>كيف نستخدم معلوماتك؟</h3>
    <p>
    هدفنا الأساسي في جمع المعلومات هو تزويدك بتجربة معززة عند استخدام الخدمة. نحن نستخدم هذه المعلومات لمراقبة عن كثب ميزات الخدمة الأكثر استخدامًا، للسماح لك بمشاهدة سجل الغسيل الخاص بك، وتخزين معلومات بطاقتك الائتمانية مع شريك الدفع المعتمد، وعرض أي عروض ترويجية قد نقوم بتشغيلها حاليًا، ورحلات الأسعار، ولتحديد الميزات التي نحتاج إلى التركيز عليها، بما في ذلك أنماط الاستخدام والمواقع الجغرافية لتحديد المكان الذي يجب أن نقدم أو نركز فيه على الخدمات و/أو الميزات و/أو الموارد. نحن نستخدم معلومات الموبايل التي تم جمعها حتى نتمكن من خدمتك.
    </p>
  </section>
<p> تستخدم الشركة عنوان بروتوكول الإنترنت (IP) الخاص بك للمساعدة في تشخيص مشكلات خادم الكمبيوتر الخاص بنا، وإدارة موقع الويب. يتم استخدام عنوان IP الخاص بك للمساعدة في تحديد هويتك، وجمع البيانات الديموغرافية الواسعة. لا يحتوي عنوان IP الخاص بك على أي معلومات شخصية عنك. </p>
<section>
    <h3> خدمة العملاء</h3>
    <p>
    بناءً على المعلومات الشخصية التي تزودنا بها، سنرسل لك رسالة ترحيب إلكترونية للتحقق من اسم المستخدم وكلمة المرور الخاصة بك. سنتواصل معك أيضًا استجابةً لاستفساراتك، ولتوفير الخدمات التي تطلبها، وسنتواصل معك عبر البريد الإلكتروني أو الهاتف أو التطبيق، وفقًا لرغباتك.
    </p>
  </section>
  <section>
    <h3> الإعلانات المستهدفة أو السلوكية </h3>
    <p>
    الإعلانات المستهدفة، المعروف أيضًا باسم الإعلان السلوكي، يستخدم المعلومات التي يتم جمعها على سلوك الفرد لتصفح الويب، مثل الصفحات التي زارها أو عمليات البحث التي قام بها. ثم يتم استخدام هذه المعلومات لتحديد إعلان "يلق" الذي يجب عرضه على فرد معين على مواقع الويب بخلاف. على سبيل المثال، إذا كنت قد أبدت تفضيلًا للسفر أثناء زيارة موقع "يلق"، فقد يتم عرض إعلان من "يلق" للبرامج المتعلقة بالسفر عند زيارة موقع آخر غير موقع "يلق". المعلومات التي يتم جمعها مرتبطة فقط بمعرف ملف تعريف ارتباط مجهول (رقم هجائي رقمي)؛ لا تتضمن أي معلومات يمكن ربطها مرة أخرى بشخص معين، مثل الاسم أو العنوان أو رقم بطاقة الائتمان. تأتي المعلومات المستخدمة للإعلانات المستهدفة إما من "يلق" أو من خلال ناشري مواقع الويب الخاصة بالجهات الخارجية.
    </p>
  </section>


  <section>
    <h3> المعلومات الخاصة بك</h3>
    <p>
    نحن لا نبيع أو نؤجر أو نتبادل معلوماتك الشخصية أو معلومات الموقع الجغرافي. سنستخدم هذه المعلومات فقط كما هو موضح في سياسة الخصوصية هذه.
    </p>
    <p>يجوز للشركة مشاركة المعلومات المجمعة التي تتضمن معلومات غير محددة وبيانات السجل مع أطراف ثالثة لتحليل الصناعة، والتوصيف الديموغرافي وتقديم إعلانات مستهدفة حول المنتجات والخدمات الأخرى.</p>
  <p>يجوز لنا توظيف شركات وأفراد من أطراف ثالثة لتسهيل خدمتنا، لتقديم الخدمة نيابة عنا، لمعالجة الدفع، وتوفير دعم العملاء، وتوفير معلومات الموقع الجغرافي لمقدمي الخدمات لدينا، لاستضافة نموذج طلب التوظيف لدينا، ولأداء الخدمات ذات الصلة بالموقع (على سبيل المثال، على سبيل المثال لا الحصر، خدمات الصيانة، إدارة قواعد البيانات، تحليلات الويب وتحسين ميزات الموقع) أو لمساعدتنا في تحليل كيفية استخدام موقعنا الإلكتروني والخدمة. لا يحق لهذه الجهات الخارجية الوصول إلى معلوماتك الشخصية إلا لأداء هذه المهام نيابة عنا، وهي ملزمة بعدم الكشف عنها أو استخدامها لأي غرض آخر.</p>
  
<p>قد نوفر أيضًا معلومات شخصية لشركائنا في العمل أو كيانات موثوقة أخرى بغرض تزويدك بمعلومات عن البضائع أو التي نعتقد أنها تهمك. يمكنك، في أي وقت، اختيار عدم تلقي هذه الاتصالات عن طريق الاتصال بتلك الجهات الخارجية مباشرة.</p>
<p>تتعاون الشركة مع الحكومة وموظفي إنفاذ القانون والأطراف الخاصة لتطبيق القانون والامتثال له. سنقوم بالإفصاح عن أي معلومات عنك إلى الحكومة أو مسؤولي إنفاذ القانون أو الأطراف الخاصة لأننا، حسب تقديرنا المطلق، نعتقد أنه ضروري أو مناسب للرد على المطالبات والإجراءات القانونية (بما في ذلك على سبيل المثال لا الحصر مذكرات الاستدعاء)، لحماية ممتلكات وحقوق الشركة أو أي طرف ثالث، لحماية سلامة الجمهور أو أي شخص، أو لمنع أو إيقاف النشاط الذي قد نعتبره، أو أن يشكل مخاطرة بأن يكون، نشاطًا غير قانوني أو غير أخلاقي</p>
  </section>

  {/* Additional sections can be added here as necessary */}
</div>
      <div className="terms-footer">
        Powered by&nbsp;
        <a href="https://mobileaders.com/">Mobileaders</a>
      </div>
    </div>
  );
}

export default TermsAndConditions;
