import React from 'react';
import { useFormik } from 'formik';
import franchiseApplySchema from '../validation/franchiseFormValidation'; // Import Yup validation schema
import '../assets/style/FranchiseApplyForm.css'; // Custom styles
import hrImage from '../assets/images/hr.png';
import ContactSection from '../components/ContactSection';
import Navbar from '../components/Navbar';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

function FranchiseApplyForm() {
  const formik = useFormik({
    initialValues: {
      fullName: '',
      email: '',
      birthDate: '',
      phone: '',
      franchiseType: '',
      numberOfBikes: '',
      requiredArea: '',
      targetAmount: '',
      hasExperience: '',
      yearsOfExperience: '',
      availability: '',
      previousExperience: '',
      franchiseManagement: '',
      agreeToTerms: false,
    },
    validationSchema: franchiseApplySchema, // Validation schema
    onSubmit: async (values, { resetForm }) => {
      try {
        const response = await fetch('https://api.yeleg.online/api/franchise-requests', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: values.fullName,
            email: values.email,
            birthdate: values.birthDate,
            phone_no: values.phone,
            type: values.franchiseType,
            vehicle_number: values.numberOfBikes,
            place: values.requiredArea,
            estimate_cost: values.targetAmount,
            has_experience: values.hasExperience=== 'true', // Convert to boolean,
            experience_years: values.yearsOfExperience,
            can_manage_project: values.availability=== 'true', // Convert to boolean,
            note: values.previousExperience,
            manage_note: values.franchiseManagement,
          }),
        });
    
        if (response.ok) {
          toast.success('تم ارسال الطلب بنجاح!'); // Success message
          resetForm(); // Reset the form after submission
        } else {
          const errorData = await response.json();
          
    
          if (errorData.errors) {
            // Loop through the errors and display them in toast notifications
            Object.keys(errorData.errors).forEach((key) => {
              errorData.errors[key].forEach((errorMsg) => {
                toast.error(`${errorMsg}`); // Show each error message
              });
            });
          } else {
            toast.error(`فشل في ارسال الطلب: ${errorData.msg || 'حدث خطأ ما'}`);
          }
        }
      } catch (error) {
        toast.error('حدث خطأ أثناء الاتصال بالخادم. حاول مرة أخرى!');
      }
    },
  })    

  return (
    <div>
      <Navbar />
      <div className="about-header text-center">
        <h1>طلب فرنشايز</h1>
        <img src={hrImage} alt="hr img" className="download-hr-image" />
      </div>

      <div className="franchise-form-section">
        <form onSubmit={formik.handleSubmit} className="franchise-form">
          {/* Section 1: Personal Information */}
          <h2 className="section-header mb-5">معلومات شخصية</h2>
          <div className="row mb-3">
            <div className="col-md-6">
              <label htmlFor="fullName" className="form-label">الاسم الثلاثي</label>
              <input
                type="text"
                className="form-control"
                id="fullName"
                name="fullName"
                value={formik.values.fullName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.fullName && formik.errors.fullName && (
                <div className="text-danger">{formik.errors.fullName}</div>
              )}
            </div>

            <div className="col-md-6">
              <label htmlFor="email" className="form-label">البريد الإلكتروني</label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.email && formik.errors.email && (
                <div className="text-danger">{formik.errors.email}</div>
              )}
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-6">
              <label htmlFor="birthDate" className="form-label">تاريخ الميلاد</label>
              <input
                type="date"
                className="form-control"
                id="birthDate"
                name="birthDate"
                value={formik.values.birthDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.birthDate && formik.errors.birthDate && (
                <div className="text-danger">{formik.errors.birthDate}</div>
              )}
            </div>

            <div className="col-md-6">
              <label htmlFor="phone" className="form-label">رقم الهاتف</label>
              <input
                type="tel"
                className="form-control"
                id="phone"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.phone && formik.errors.phone && (
                <div className="text-danger">{formik.errors.phone}</div>
              )}
            </div>
          </div>

          {/* Section 2: Franchise Information */}
          <h2 className="section-header my-5">معلومات الفرنشايز</h2>
          <div className="row mb-3">
            <div className="col-md-4">
              <label htmlFor="franchiseType" className="form-label">نوع الفرنشايز</label>
              <select
                className="form-select"
                id="franchiseType"
                name="franchiseType"
                value={formik.values.franchiseType}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="">اختر نوع الفرنشايز</option>
                <option value="من الصفر">من الصفر</option>
                <option value="تحويل مشروع">تحويل مشروع</option>
              </select>
              {formik.touched.franchiseType && formik.errors.franchiseType && (
                <div className="text-danger">{formik.errors.franchiseType}</div>
              )}
            </div>

            <div className="col-md-4">
              <label htmlFor="numberOfBikes" className="form-label">رقم الدراجة </label>
              <input
                type="text"
                className="form-control"
                id="numberOfBikes"
                name="numberOfBikes"
                value={formik.values.numberOfBikes}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.numberOfBikes && formik.errors.numberOfBikes && (
                <div className="text-danger">{formik.errors.numberOfBikes}</div>
              )}
            </div>

            <div className="col-md-4">
              <label htmlFor="requiredArea" className="form-label">المنطقة المطلوبة</label>
              <input
                type="address"
                className="form-control"
                id="requiredArea"
                name="requiredArea"
                value={formik.values.requiredArea}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.requiredArea && formik.errors.requiredArea && (
                <div className="text-danger">{formik.errors.requiredArea}</div>
              )}
            </div>
          </div>

          {/* Section 3: Qualifications and Experience */}
          <h2 className="section-header my-5">المؤهلات والخبرات</h2>
          <div className="row mb-3">
            <div className="col-md-6">
              <label htmlFor="targetAmount" className="form-label">المبلغ المستهدف</label>
              <input
                type="number"
                className="form-control"
                id="targetAmount"
                name="targetAmount"
                value={formik.values.targetAmount}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.targetAmount && formik.errors.targetAmount && (
                <div className="text-danger">{formik.errors.targetAmount}</div>
              )}
            </div>

            <div className="col-md-6">
              <label htmlFor="hasExperience" className="form-label">هل توجد خبرة سابقة في هذا المجال</label>
              <select
                className="form-select"
                id="hasExperience"
                name="hasExperience"
                value={formik.values.hasExperience}
                onChange={(e) => {
                  const value = e.target.value === 'true'; // Convert string to boolean
                  formik.setFieldValue('availability', value);
                }}
                onBlur={formik.handleBlur}
              >
                <option value="">اختر</option>
                <option value="true">نعم</option>
                <option value="false">لا</option>
              </select>
              {formik.touched.hasExperience && formik.errors.hasExperience && (
                <div className="text-danger">{formik.errors.hasExperience}</div>
              )}
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-6">
              <label htmlFor="yearsOfExperience" className="form-label"> عدد سنوات الخبرة</label>
              <input
                type="number"
                className="form-control"
                id="yearsOfExperience"
                name="yearsOfExperience"
                value={formik.values.yearsOfExperience}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.yearsOfExperience && formik.errors.yearsOfExperience && (
                <div className="text-danger">{formik.errors.yearsOfExperience}</div>
              )}
            </div>

            <div className="col-md-6">
              <label htmlFor="availability" className="form-label"> هل تتفرغ لإدارة المشروع؟</label>
           
                   <select
                className="form-select"
                id="availability"
                name="availability"
                value={formik.values.availability}
                onChange={(e) => {
                  const value = e.target.value === 'true'; // Convert string to boolean
                  formik.setFieldValue('availability', value);
                }}
                onBlur={formik.handleBlur}
              >
                <option value="">اختر</option>
                <option value="true">نعم</option>
                <option value="false">لا</option>
              </select>
              {formik.touched.availability && formik.errors.availability && (
                <div className="text-danger">{formik.errors.availability}</div>
              )}
            </div>
          </div>

          
         

          <div className="row mb-3">
            <div className="col-md-12">
              <label htmlFor="previousExperience" className="form-label">اذكر نبذة عن الخبرة والتجارب السابقة</label>
              <textarea
                className="form-control"
                id="previousExperience"
                name="previousExperience"
                rows="3"
                value={formik.values.previousExperience}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.previousExperience && formik.errors.previousExperience && (
                <div className="text-danger">{formik.errors.previousExperience}</div>
              )}
            </div>
          </div>

          <div className="row mb-3">
            <div className="col-md-12">
              <label htmlFor="franchiseManagement" className="form-label">كيفية تشغيل وإدارة فرنشايز</label>
              <textarea
                className="form-control"
                id="franchiseManagement"
                name="franchiseManagement"
                rows="3"
                value={formik.values.franchiseManagement}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.franchiseManagement && formik.errors.franchiseManagement && (
                <div className="text-danger">{formik.errors.franchiseManagement}</div>
              )}
            </div>
          </div>

          <div className="form-check mb-3">
            <input
              className="form-check-input"
              type="checkbox"
              id="agreeToTerms"
              name="agreeToTerms"
              checked={formik.values.agreeToTerms}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <label className="form-check-label mx-3" htmlFor="agreeToTerms">
              أوافق على الشروط والأحكام
            </label>
            {formik.touched.agreeToTerms && formik.errors.agreeToTerms && (
              <div className="text-danger">{formik.errors.agreeToTerms}</div>
            )}
          </div>

          <div className="text-center">
          <button type="submit" className="btn text-white w-100" style={{ backgroundColor: '#6D48FF' }}>
            ارسال الطلب الان
          </button>
          </div>
        </form>
      </div>
      
      <ContactSection />

       {/* ToastContainer to display toasts */}
       <ToastContainer position="top-right" autoClose={5000} />
    </div>
  );
}

export default FranchiseApplyForm;
