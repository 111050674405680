import React from 'react';
import '../assets/style/about.css'; 
import hrImage from '../assets/images/hr.png';
import ContactSection from '../components/ContactSection';
import Navbar from '../components/Navbar'; 

function AboutYaleg() {
  
  return (
    <div className="about-yaleg">
      <Navbar />
      <div className="about-header">
        <h1>عن يلق</h1>
        <img src={hrImage} alt="hr img" className="download-hr-image" /> 
      </div>

      <section className="about-section">
        <div className="about-content "  >
          
          <p>
            في يلق، نكرّس جهودنا لتقديم تجربة تنظيف سيارات ومنازل مريحة حسب الطلب. 
            منصتنا مصممة لتوفير الراحة والخدمة عالية الجودة مباشرة إلى باب منزلك، سواء كنت ترغب في تنظيف سيارتك أو تحتاج إلى تنظيف منزلي احترافي. 
            يلق يضمن لك الحصول على خدمة متميزة ببضع نقرات فقط، دون التنازل عن الجودة أو الكفاءة.
          </p>

          <h2>رؤيتنا</h2>
          <p>
            نطمح أن نكون المزود الرائد لخدمات العناية بالسيارات وتنظيف المنازل، مع تقديم حلول صديقة للبيئة وفعّالة وموثوقة. 
            رؤيتنا هي توفير تجربة خالية من المتاعب لكل عميل، لضمان بقاء سيارته ومنزله نظيفين، مع الاستفادة من سهولة استخدام منصتنا.
          </p>

          <h2>رسالتنا</h2>
          <p>
            مهمتنا هي تبسيط صيانة السيارات وتنظيف المنازل من خلال الاستفادة من التكنولوجيا لتقديم خدمة استثنائية. 
            نهدف إلى خلق تجربة تركز على راحة العملاء ورضاهم، مع التركيز على الاستدامة والجودة. 
            سواء كانت سيارتك أو منزلك، يلق ملتزم بتقديم خدمات تنظيف متميزة تلبي احتياجاتك.
          </p>

          <h2>الجودة أولاً</h2>
          <p>
            في يلق، الجودة هي جوهر كل ما نقوم به. من غسل السيارات إلى خدمات تنظيف المنازل، فريقنا مدرّب وفق أعلى المعايير. 
            نستخدم منتجات صديقة للبيئة وتقنيات متقدمة لضمان أن تكون سيارتك ومنزلك في حالة مثالية. 
            هدفنا هو تجاوز توقعات العملاء من خلال تقديم التميز في كل خدمة.
          </p>
        </div>
      </section>

      <ContactSection />
    </div>
  );
}

export default AboutYaleg;
