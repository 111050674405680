import React from "react";
import "../assets/style/WhyYalegSection.css";
import { Link } from 'react-router-dom';

function WhyYalegSection({ buttonText = "جرب يلق الأن", buttonLink = "#" }) {
  return (
    <section className="why-yaleg-section px-5">
      <div className="right-col">
        <div className="advantage-item">
          <h3> خبرة مضمونة</h3>
          <p>
            تتمتع بسمعة قوية في مجال تنظيف السيارات والمنازل كعلامة تجارية موثوقة تقدم خدمات عالية الجودة. من خلال الاستثمار معنا، يمكنك الاستفادة من هذه الخبرة المتميزة والنجاح المستدام
          </p>
        </div>
        <div className="advantage-item">
          <h3> الشراكة من أجل النمو</h3>
          <p>
          يلق ليست مجرد مزود خدمة؛ نحن شريك في النمو. من خلال الاستثمار في يلق، أنت تتماشى مع نموذج عمل مصمم للنمو. نحن نسعى باستمرار لتوسيع عروضنا وتحسين تجربة العملاء. يضمن هذا الالتزام بالنمو أن يتمكن شركاؤنا من التوسع معنا، مستفيدين من الأسواق والخدمات الجديدة مع زيادة الطلب
          </p>
        </div>
        <div className="advantage-item">
          <h3 > دعم متكامل</h3>
          <p>
          نحن هنا لمساعدتك في كل خطوة على الطريق. بدءًا من تقديم التدريب والمساعدة الفنية، وصولًا إلى الإشراف والجهود التسويقية، نضمن لك الدعم الكامل لتحقيق النجاح
          </p>
        </div>

        <div className="advantage-item">
          <h3> تحقيق رضا العملاء</h3>
          <p> نركز على ضمان رضا العميل من خلال تقديم خدمات ذات جودة عالية تلبي توقعاتهم</p>
        </div>

      

        <div className="advantage-item">
          <h3> عوائد مربحة</h3>
          <p>
          نقدم لك مبلغًا شهريًا مرنًا يعتمد على أدائك، مما يمنحك الفرصة لزيادة دخلك وتحقيق نجاح مالي ملموس
          </p>
        </div>

        <div className="advantage-item">
          <h3> الجودة والثقة</h3>
          <p>
          بُنيت علامتنا التجارية على قيم الثقة والجودة. تعكس كل تفاعل مع يلق التزامنا بهذه المبادئ. من عملية الحجز سهلة الاستخدام إلى اللمسة النهائية لخدمات التنظيف والعناية، نهدف إلى تجاوز التوقعات. هذا الالتزام بالتميز هو ما يميزنا ويجعل يلق الخيار المفضل للعملاء والمستثمرين على حد سواء
          </p>
        </div>
      </div>

      <div className="left-col">
        <h2>لماذا تستثمر معنا في يلق ؟</h2>
        <p>
        في يلق، نحن لا نبني مجرد خدمة، بل نخلق نظامًا متكاملًا من الراحة والجودة ورضا العملاء. الاستثمار في يلق يعني أن تصبح جزءًا من شبكة متنامية تجمع بين التكنولوجيا المتطورة والشغف بالتميز في مجالات العناية بالسيارات وتنظيف المنازل. مع التركيز القوي على الابتكار وتلبية احتياجات العملاء، تقدم يلق فرصًا للمستثمرين ليكونوا جزءًا من سوق خدماتي متنامٍ يتوسع مع زيادة الطلب على الخدمات حسب الطلب.
        </p>
        <Link to={buttonLink} className="try-app-button">
          {buttonText}
        </Link>
      </div>
    </section>
  );
}

export default WhyYalegSection;
