import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import TermsAndConditions from './pages/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import AboutYaleg from './pages/AboutYaleg';
import FranchiseSteps from './pages/FranchiseSteps'
import AboutFranchise from './pages/AboutFranchise'
import FranchiseApplyForm from './pages/FranchiseApplyForm'

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/about" element={<AboutYaleg />} />
        <Route path="/steps" element={<FranchiseSteps />} />
        <Route path="/about-franchise" element={<AboutFranchise />} />
        <Route path="/contact" element={<FranchiseApplyForm />} />
        
      </Routes>
    </Router>
  );
}

export default App;
