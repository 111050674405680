import * as yup from 'yup';

const franchiseFormValidationSchema = yup.object().shape({
  fullName: yup.string().required('الاسم الثلاثي مطلوب'),
  email: yup.string().email('بريد إلكتروني غير صالح').required('البريد الإلكتروني مطلوب'),
  birthDate: yup.date().required('تاريخ الميلاد مطلوب'),
  phone: yup.string().matches(/^\+?\d+$/, 'رقم الهاتف يجب أن يحتوي على أرقام فقط').required('رقم الهاتف مطلوب'),
  franchiseType: yup.string().required('نوع الفرنشايز مطلوب'),
  numberOfBikes: yup.string().required('عدد الدراجات مطلوب'),
  requiredArea: yup.string().required('المنطقة المطلوبة مطلوبة'),
  targetAmount: yup.number().required('المبلغ المستهدف مطلوب'),
  hasExperience: yup.boolean().required('يرجى اختيار الخبرة'),
  // yearsOfExperience: yup.number().required('عدد سنوات الخبرة مطلوب').min(0, 'يجب أن تكون القيمة صفر أو أكثر'),
  availability: yup.boolean().required('يرجى تحديد التفرغ'),
  // previousExperience: yup.string().required('يرجى إدخال نبذة عن الخبرة السابقة'),
  // franchiseManagement: yup.string().required('يرجى إدخال كيفية إدارة الفرنشايز'),
  agreeToTerms: yup.boolean().oneOf([true], 'يجب الموافقة على الشروط والاحكام'),
});

export default franchiseFormValidationSchema;
