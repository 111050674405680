import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLaptop, faSearch, faHandshake, faFileContract, faProjectDiagram, faRocket } from '@fortawesome/free-solid-svg-icons';
import '../assets/style/FranchiseSteps.css'; 
import hrImage from '../assets/images/hr.png';
import ContactSection from '../components/ContactSection';
import Navbar from '../components/Navbar'; 
import { Link } from 'react-router-dom';

function FranchiseSteps() {
  const [activeStep, setActiveStep] = useState(null);

  // Detecting the step in view and changing line color
  useEffect(() => {
    const handleScroll = () => {
      const steps = document.querySelectorAll('.step');
      steps.forEach((step, index) => {
        const rect = step.getBoundingClientRect();
        if (rect.top >= 0 && rect.top <= window.innerHeight / 2) {
          setActiveStep(index);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const stepsData = [
    { id: 1, icon: faLaptop, header: 'التقديم عبر الموقع', description: '.الخطوة الأولى في التقديم هي تعبئة النموذج عبر الموقع' },
    { id: 2, icon: faSearch, header: 'مراجعة الطلب', description: '.مراجعة الطلب المقدم من قبل فريقنا المختص' },
    { id: 3, icon: faHandshake, header: 'التقييم والاجتماع', description: '.إجراء تقييم شامل وجدولة اجتماع مع العميل' },
    { id: 4, icon: faFileContract, header: 'مذكرة التفاهم والرسوم الأولية', description: '.توقيع مذكرة التفاهم ودفع الرسوم الأولية لبدء العمل' },
    { id: 5, icon: faProjectDiagram, header: 'التخطيط للمشروع', description: '.تحديد خطة العمل والمراحل المطلوبة لإنجاح المشروع' },
    { id: 6, icon: faRocket, header: 'انطلاق المشروع', description: '.إطلاق المشروع بشكل رسمي والبدء في العمل' },
  ];

  return (
    <div >
      <Navbar />
      <div className="about-header">
        <h1>خطوات التقدم على فرنشايز</h1>
        <img src={hrImage} alt="hr img" className="download-hr-image" />
      </div>

      <section className="about-steps-section">
        {stepsData.map((step, index) => (
          <div key={step.id} className={`step ${activeStep === index ? 'active' : ''}`}>
            <div className="step-content">
              <div className="step-icon">
                <FontAwesomeIcon icon={step.icon} style={{ color: '#6D48FF' }} />
              </div>
              <div className="step-text">
                <h2 className="step-header">{step.header}</h2>
                <p className="step-description">{step.description}</p>
              </div>
            </div>
          </div>
        ))}
        
      </section>

      <div className='franchise-apply-btn-container'>
      <Link to="/about-franchise" className="franchise-apply-btn"> طلب فرنشايز الان</Link>
      </div>
      

      <ContactSection />
    </div>
  );
}

export default FranchiseSteps;
